import React from "react"

import Layout from "../../components/layout"
import ResourceHome from "../../components/resources/resourceHome"

const IndexPage = () => {
  return (
    <Layout>
      <ResourceHome />
    </Layout>
  )
}

export default IndexPage
