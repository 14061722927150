import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import ResourceSection from "./resourceSection"
import ResourceDetails from "./resourceDetails"

const listing = () => (
  <>
    <motion.div
      initial={{
        x: 400,
      }}
      animate={{
        x: 0,
      }}
      transition={{
        duration: 0.5,
      }}
    >
      <ResourceDetails />
      <ResourceSection />
    </motion.div>
  </>
)

export default listing
