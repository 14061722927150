import React from "react"
import styled from "styled-components"

const blogDetails = () => (
  <Details>
    <div className="wrapper">
      <p className="display-1">All the best resources to help your classes!</p>
      <div className="blog-deet">
        <p className="deet-header">Services and Tools</p>
        <p>
          Every day it seems like there's more and more ed tech services that
          are popping up. We're here to help you make sense of them. Check out
          our curated list of the best services and tools around.
        </p>
      </div>
      <div className="blog-deet">
        <p className="deet-header">Graphic Organizers</p>
        <p>
          Whether you're still using paper and pencil in the classroom or you've
          entered a 1:1 environment, graphic organizers are a great way of
          keeping students engaged and on-task. Our collection balances
          old-school classics and new solutions.
        </p>
      </div>
      <div className="blog-deet">
        <p className="deet-header">Lesson Plans</p>
        <p>
          Sometimes it just helps to have lessons in front of you. These can
          serve as templates or, in a pinch, an emergency lesson plan when
          things go awry. We've created easy-to-use, modular lessons that can be
          used as is or in pieces.
        </p>
      </div>
    </div>
  </Details>
)

const Details = styled.div`
  background: var(--grey-100);
  padding-bottom: 200px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  min-height: 50vh;
  margin-left: 100px;
  margin-right: 100px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    margin-left: 20px;
    margin-right: 20px;

    .display-1 {
      font-size: 2.4rem;
      text-align: left;
    }
  }

  }

  .display-1 {
    grid-column: 1/-1;
    text-align: center;
  }

  .deet-header {
    font-weight: 600;
    font-size: 18px;
  }

  .blog-deet {
    display: grid;
    border-top: 5px solid var(--black);
    align-content: space-between;
  }

  a {
    text-decoration: none;
    color: var(--blue-200);
    transition: ease-in-out .4s;
  }
  
  a:hover {
    color: var(--teal);
  }
`

export default blogDetails
